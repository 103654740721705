import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

import MenuAppBar from '../../utils/MenuAppBar';
import IsLoading from '../../utils/IsLoading';
import { refreshToken, getUser } from '../account/AccountSlice';
import { isEmpty } from '../../utils/Utils';
import { unsetPymtDetails } from '../mppos/MpPosSlice';
import { unsetReservationDetails } from '../apppos/AppPosSlice';

import { HomeOptions, HomeButtons } from './HomeNav';

function HomePage () {
    const { isLoading, isAuthenticated, user } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const homeOptions = HomeOptions(user.app)
    
    useEffect(() => {
        dispatch(unsetPymtDetails());
        dispatch(unsetReservationDetails());
        if (!isAuthenticated && localStorage.getItem('refresh')) {
            dispatch(refreshToken());
        }
        if (isAuthenticated && isEmpty(user)) {
            dispatch(getUser());
        }
        if (isEmpty(localStorage.getItem('refresh'))) {
            navigate('/account/login');
        }
    }, [isAuthenticated, user, dispatch, navigate])

    return (
        <Container maxWidth='sm'>
        <MenuAppBar />
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <HomeButtons options={homeOptions} />
            )}
            
        </Container>
    )
}

export default HomePage;