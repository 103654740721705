import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Container, Grid2, Paper, TextField, Typography } from '@mui/material';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';

import IsLoading from '../../utils/IsLoading';
import { resetPasswordConfirm } from './AccountSlice';

function ResetPasswordConfirm () {
    const { isLoading } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const { uid, token } = useParams();
    const [newPassword, setNewPassword] = useState(null);
    const [retypeNewPassword, setRetypeNewPassword] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordData = {
            uid: uid,
            token: token,
            new_password: newPassword,
            re_new_password: retypeNewPassword,
        }
        dispatch(resetPasswordConfirm(passwordData));
    }

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h5' align="center" gutterBottom>Reset password</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='New password' type='password' required onChange={(e) => setNewPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Repeat new password' type='password' required onChange={(e) => setRetypeNewPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<LockResetRoundedIcon />}>Reset</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </Paper>
            )}
        </Container>
    )
}

export default ResetPasswordConfirm;