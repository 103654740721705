import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './components/account/AccountSlice';
import mpPosReducer from './components/mppos/MpPosSlice';
import appPosReducer from './components/apppos/AppPosSlice';
import posReducer from './components/pos/PosSlice';


const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
    reducer: {
        router: routerReducer,
        account: accountReducer,
        mpPos: mpPosReducer,
        appPos: appPosReducer,
        pos: posReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware)
});

export const history = createReduxHistory(store);
