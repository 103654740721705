import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid2, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slider, Switch, TextField, Typography } from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';


function PaymentDetails ({ setPaymentDetails }) {
    const { user } = useSelector((state) => state.account);
    const [paymentType, setPaymentType] = useState('DB');
    const [currency, setCurrency] = useState(user.default_currency || '');
    const [amount, setAmount] = useState(0);
    const [tip, setTip] = useState(0);
    const [ref, setRef] = useState('');
    const [scanToPay, setScanToPay] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const paymentData = {
            payment_type: paymentType,
            currency: currency.toUpperCase(),
            amount: amount,
            tip_amount: tip,
            reference: ref,
            scan_to_pay: scanToPay,
        }
        if (validatePaymentDetails(paymentData)) {
            paymentData.amount = parseFloat(amount).toFixed(2);
            paymentData.tip_amount = Math.max(parseFloat(tip).toFixed(2), 0);
            setPaymentDetails(paymentData);
        }
    }

    return (
        <Paper sx={{ padding: 2 }}>
            <form onSubmit={handleSubmit}>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography variant='h6'>Payment details</Typography>
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <FormControl>
                            <FormLabel id='paymentTypeRadio'>Payment type</FormLabel>
                            <RadioGroup row aria-labelledby='paymentTypeRadio' value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                                <FormControlLabel value='DB' control={<Radio color='secondary' />} label='Debit' />
                                <FormControlLabel value='PA' control={<Radio color='secondary' />} label='Pre-auth' />
                            </RadioGroup>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 4}}>
                        <FormControl variant='standard' fullWidth>
                            <InputLabel id='currencyLabel'>Currency</InputLabel>
                            <Select
                                labelId="currencyLabel"
                                id="currencyInput"
                                value={currency}
                                label="Currency"
                                onChange={(e) => setCurrency(e.target.value)}
                            >
                                { user?.mp_currencies.map((currency) => (
                                    <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 8}}>
                        <TextField variant='standard' label='Amount' type='number' required value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </Grid2>
                    <Grid2 size={{ xs: 8}}>
                        <FormControl fullWidth>
                            <FormLabel id='tipCalculator'>Tip</FormLabel>
                            <TipSlider amount={amount} setTip={setTip} />
                        </FormControl>
                    </Grid2>
                    <Grid2 size={{ xs: 4}}>
                        <TextField variant='standard' label='Tip amount' type='number' value={tip} onChange={(e) => setTip(e.target.value)} />
                    </Grid2>
                    <Grid2 size={{ xs: 12}}>
                        <TextField variant='standard' label='Reference' fullWidth value={ref} onChange={(e) => setRef(e.target.value)} />
                    </Grid2>
                    <Grid2 size={{ xs: 12}}>
                        
                    </Grid2>
                    <Grid2 size={{ xs: 6}} alignItems='center'>
                        <FormControlLabel
                            label={'Scan to pay'}
                            control={<Switch
                                checked={scanToPay}
                                onChange={(e) => setScanToPay(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                color='secondary'
                            />}
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 6}} alignItems='center'>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type='submit' variant='contained' endIcon={<NavigateNextRoundedIcon />}>Next</Button>
                        </Box>
                    </Grid2>
                </Grid2>   
            </form>
        </Paper>
    )
}

export default PaymentDetails;

function validatePaymentDetails (paymentDetails) {
    let isValid = true;
    if (!(paymentDetails.payment_type === 'DB' || paymentDetails.payment_type === 'PA')) {
        isValid = false;
        toast.error('Invalid payment type');
    }
    if (!paymentDetails.currency || paymentDetails.currency.length !== 3) {
        isValid = false;
        toast.error('Invalid currency');
    }
    if (isNaN(parseFloat(paymentDetails.amount))) {
        isValid = false;
        toast.error('Invalid amount');
    }
    if (isNaN(parseFloat(paymentDetails.tip_amount))) {
        isValid = false;
        toast.error('Invalid tip');
    }
    return isValid;
}


function TipSlider ({ amount, setTip }) {
    const calculateTip = (newValue) => {
        const parsedAmount = parseFloat(amount);
        if (parsedAmount > 0) {
            setTip((newValue/100*parsedAmount).toFixed(2));
        }
    }

    const marks = [10, 20, 30, 40, 50].map(i => ({value: i, label: `${i}%`}))

    return (
        <Slider
            aria-labelledby='tipCalculator'
            defaultValue={0}
            getAriaLabel={(value) => `${value}%`}
            valueLabelDisplay='auto'
            marks={marks}
            step={5}
            min={0}
            max={50}
            onChange={(_, newValue) => calculateTip(newValue)}
            color='secondary'
        />
    )
}