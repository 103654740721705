import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';

const initialState = {
    isLoading: false,
    verified: false,
    results: {}
}


export const verifyPayment = createAsyncThunk(
    'pos/verifyPayment',
    async (referenceNumber, { _, rejectWithValue }) => {
        try {
            const response = await axios.get(`/verify?referenceNumber=${referenceNumber}`);
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const posSlice = createSlice({
    name: 'pos',
    initialState,
    reducers: {
        unsetVerification (state) {
            state.verified = false;
            state.results = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(verifyPayment.pending, (state) => {
            state.isLoading = true;
        }).addCase(verifyPayment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.verified = action.payload.verified;
            state.results = action.payload.results;
        }).addCase(verifyPayment.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { unsetVerification } = posSlice.actions;
export default posSlice.reducer;