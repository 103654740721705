import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Grid2, Paper, TextField, Typography } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

import IsLoading from '../../utils/IsLoading';
import { resetPassword } from './AccountSlice';

function ResetPassword () {
    const { isLoading } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const [email, setEmail] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailData = {
            email: email,
        }
        dispatch(resetPassword(emailData));
    }

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h5' align="center" gutterBottom>Reset password</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Email address' type='email' required onChange={(e) => setEmail(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<SendRoundedIcon />}>Send</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </Paper>
            )}
        </Container>
    )
}

export default ResetPassword;