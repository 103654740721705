import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

import MenuAppBar from './MenuAppBar';
import { isEmpty } from './Utils';


function Pos () {
    const { isAuthenticated, user } = useSelector((state) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated || isEmpty(user)) {
            navigate('/account/login');
        }
    }, [isAuthenticated, user, navigate]);

    return (
        <Container maxWidth='sm'>
            <MenuAppBar />
            <Outlet />
        </Container>
    )
}

export default Pos;