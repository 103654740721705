import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { Login, Logout, ChangeCircleOutlined } from '@mui/icons-material';

import { unsetUser } from '../components/account/AccountSlice';

function AccountMenu (props) {
    const { isAuthenticated } = useSelector((state) => state.account)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(unsetUser());
        navigate('/');
    }

    return (
        <Menu id='account-menu' anchorEl={props.anchorEl} open={props.open} onClose={props.onClose} transformOrigin={{horizontal: 'right', vertical: 'top'}} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <MenuItem>
                <Typography variant='subtitle2' align='center'>
                    {props.loggedUser}
                </Typography>
            </MenuItem>
            { isAuthenticated ? (
                <div>
                <MenuItem onClick={() => navigate('/account/user')} >
                    <ListItemIcon>
                        <ChangeCircleOutlined fontSize='small' />
                    </ListItemIcon>
                    User details
                </MenuItem>
                <MenuItem onClick={() => navigate('/account/default-currency')} >
                    <ListItemIcon>
                        <ChangeCircleOutlined fontSize='small' />
                    </ListItemIcon>
                    Default currency
                </MenuItem>
                <MenuItem onClick={() => navigate('/account/change-password')} >
                    <ListItemIcon>
                        <ChangeCircleOutlined fontSize='small' />
                    </ListItemIcon>
                    Password
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => logout()} >
                    <ListItemIcon>
                        <Logout fontSize='small' />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                </div>
            ) : (
                <MenuItem onClick={() => navigate('/account/login')} >
                    <ListItemIcon>
                        <Login fontSize='small' />
                    </ListItemIcon>
                    Login
                </MenuItem>
            )}
        </Menu>
    )
}

export default AccountMenu;