import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';


function AlertComponent ({result, message, showTitle}) {
    const alertTitle = {
        success: 'Success',
        warning: 'Warning',
        error: 'Error'
    }

    return (
        <Alert
            severity={result}
            iconMapping={{
                success: <CheckCircleIcon fontSize='large' />,
                warning: <WarningIcon fontSize='large' />,
                error: <ErrorIcon fontSize='large' />
            }}
            variant='outlined'
            multiline='true'
            sx={{ display: 'flex', alignItems: 'center' }}
        >
            { showTitle ? <AlertTitle>{ alertTitle[result] }</AlertTitle> : null }
            {message}
        </Alert>
    )
}

export default AlertComponent;