import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from'react-toastify';
import { Button, Container, Grid2, Paper, TextField, Typography } from '@mui/material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

import IsLoading from '../../utils/IsLoading';
import { changePassword } from './AccountSlice';

function ChangePassword () {
    const { isLoading, isAuthenticated } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [retypeNewPassword, setRetypeNewPassword] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordData = {
            new_password: newPassword,
            re_new_password: retypeNewPassword,
            current_password: currentPassword,
        }
        if (newPassword === retypeNewPassword) {
            dispatch(changePassword(passwordData));
        } else {
            toast.error('Passwords do not match');
        }
    }

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate])

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h5' align="center" gutterBottom>Change password</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Current password' type='password' required onChange={(e) => setCurrentPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='New password' type='password' required onChange={(e) => setNewPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Repeat new password' type='password' required onChange={(e) => setRetypeNewPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<ChangeCircleOutlinedIcon />}>Change</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </Paper>
            )}
        </Container>
    )
}

export default ChangePassword;