import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid2 } from '@mui/material';

import ReservationDetails from './ReservationDetails';
import PaymentDetails from '../../utils/PaymentDetails';
import IsLoading from '../../utils/IsLoading';
import { setPymtDetails, getQrCode } from '../mppos/MpPosSlice';
import { isEmpty } from '../../utils/Utils';


function GetAppPosDetails () {
    const { isLoading: isLoadingReservation, reservation_details } = useSelector((state) => state.appPos);
    const { isLoading: isLoadingQrCode, qr_code } = useSelector((state) => state.mpPos); 
    const [paymentDetails, setPaymentDetails] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!paymentDetails.reservation_id && !isEmpty(reservation_details) && !isEmpty(paymentDetails)) {
            setPaymentDetails({
                ...paymentDetails,
                reservation_id: reservation_details.reservation_id,
            });
        }
        if (!isEmpty(paymentDetails) && paymentDetails.reservation_id && isEmpty(qr_code)) {
            dispatch(setPymtDetails(paymentDetails));
            dispatch(getQrCode(paymentDetails));
        }
        if (!isEmpty(qr_code) && !isEmpty(paymentDetails)) {
            if (paymentDetails.scan_to_pay) {
                navigate('/mp-pos/qr-code');
            } else {
                if (!isEmpty(qr_code?.long_url)) {
                    window.location.href = qr_code.long_url;
                }
            }
        }
    }, [paymentDetails, reservation_details, qr_code, navigate, dispatch])

    return (
        <div>
            { isLoadingQrCode ? <IsLoading isLoading={isLoadingQrCode} /> : (
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12}}>
                        <ReservationDetails />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        { isLoadingReservation || !isEmpty(reservation_details) ? <PaymentDetails setPaymentDetails={setPaymentDetails} /> :  null }
                    </Grid2>
                </Grid2>
            ) }
        </div>
    )
}

export default GetAppPosDetails;