import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { toast } from 'react-toastify';


import { setAxiosAuthToken, toastOnError } from '../../utils/Utils';


const initialState = {
    isAuthenticated: false,
    user: {},
    isLoading: false
}


const fetchUserData = async () => {
    try {
        const user = await axios.get('/auth/users/me/');
        return user.data;
    } catch (error) {
        throw error;
    }
}


export const login = createAsyncThunk(
    'account/login',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const tokens = await axios.post('/auth/jwt/create/', userData);
            dispatch(setTokens(tokens.data));
            const user = await fetchUserData();
            dispatch(setUser(user));
            return user
        } catch (error) {
            toastOnError(error);
            dispatch(push('/account/login'));
            return rejectWithValue(error.response.data);
        }
    }
)


export const getUser = createAsyncThunk(
    'account/getUser',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const user = await fetchUserData();
            dispatch(setUser(user));
            return user;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateUser = createAsyncThunk(
    'account/updateUser',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.put('/auth/users/me/', userData);
            dispatch(setUser(response.data));
            toast.success('User updated');
            return response.data;
        } catch (error) {
            toast.error('Email already exists');
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateDefaultCurrency = createAsyncThunk(
    'account/updateDefaultCurrency',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.put('/account/default_currency/', data);
            dispatch(setDefaultCurrency(response.data));
            toast.success('Default currency updated');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const refreshToken = createAsyncThunk(
    'account/refreshAuth',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const tokenData = {
                token: localStorage.getItem('refresh'),
            }
            const verifyToken = await axios.post('/auth/jwt/verify/', tokenData);
            if (verifyToken.status === 200) {
                const refreshData = {
                    refresh: tokenData.token
                }
                const newToken = await axios.post('/auth/jwt/refresh/', refreshData);
                dispatch(updateToken(newToken.data));
            } else {
                dispatch(unsetUser());
                dispatch(push('account/login'));
            }
            return verifyToken.data;

        } catch (error) {
            console.log(error)
            toastOnError(error);
            dispatch(unsetUser());
            dispatch(push('account/login'));
            return rejectWithValue(error.response.data);
        }
    }
)


export const changePassword = createAsyncThunk(
    'account/changePassword',
    async (passwordData, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post('/auth/users/set_password/', passwordData);
            toast.success('Password changed');
            dispatch(push('/'));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const resetPassword = createAsyncThunk(
    'account/resetPassword',
    async (emailData, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post('/auth/users/reset_password/', emailData);
            toast.success('Email sent');
            dispatch(push('/'));
            return response.data;
        } catch (error) {
            toast.error('Email address not found. Contact Support');
            return rejectWithValue(error.response.data);
        }
    }
)


export const resetPasswordConfirm = createAsyncThunk(
    'account/resetPasswordConfirm',
    async (passwordData, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post('/auth/users/reset_password_confirm/', passwordData);
            toast.success('Password reset');
            dispatch(push('/account/login'));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setTokens (state, action) {
            setAxiosAuthToken(action.payload.access)
            state.isAuthenticated = true;
            localStorage.setItem('refresh', action.payload.refresh);
        },
        updateToken (state, action) {
            setAxiosAuthToken(action.payload.access)
            state.isAuthenticated = true;
        },
        setUser (state, action) {
            state.user = action.payload;
        },
        setDefaultCurrency (state, action) {
            state.user.default_currency = action.payload.default_currency;
        },
        unsetUser (state) {
            state.isAuthenticated = false;
            state.user = {};
            localStorage.removeItem('refresh');
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        }).addCase(login.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(login.rejected, (state) => {
            state.isLoading = false;
        }).addCase(refreshToken.pending, (state) => {
            state.isLoading = true;
        }).addCase(refreshToken.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(refreshToken.rejected, (state) => {
            state.isLoading = false;
        }).addCase(changePassword.pending, (state) => {
            state.isLoading = true;
        }).addCase(changePassword.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(changePassword.rejected, (state) => {
            state.isLoading = false;
        }).addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
        }).addCase(resetPassword.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(resetPassword.rejected, (state) => {
            state.isLoading = false;
        }).addCase(resetPasswordConfirm.pending, (state) => {
            state.isLoading = true;
        }).addCase(resetPasswordConfirm.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(resetPasswordConfirm.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateUser.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateUser.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateUser.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getUser.pending, (state) => {
            state.isLoading = true;
        }).addCase(getUser.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getUser.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateDefaultCurrency.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateDefaultCurrency.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateDefaultCurrency.rejected, (state) => {
            state.isLoading = false;
        })
    },
});

export const { setTokens, updateToken, setUser, setDefaultCurrency, unsetUser } = accountSlice.actions;
export default accountSlice.reducer;