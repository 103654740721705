import React from 'react';
import { Backdrop, Box, CircularProgress, Grid2, Typography } from '@mui/material';


function IsLoading ({isLoading}) {
    return (
        <Backdrop 
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading} 
        >
            <Grid2 container spacing={2} align='center'>
                <Grid2 size={{ xs:12 }}>
                    <Box>
                        <CircularProgress color='info' />
                        <Typography variant='subtitle1' color='info'>Please wait...</Typography>
                    </Box>
                </Grid2>
            </Grid2>            
        </Backdrop>
    )
}

export default IsLoading;