import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid2, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';


export function HomeOptions (pms) {
    return [
        {
            name: pms || 'PMS',
            description: `${pms} POS`,
            icon: <AccountBalanceWalletRoundedIcon />,
            destination: '/app-pos/details',
        },
        {
            name: 'MP',
            description: 'MP POS',
            icon: <PaymentRoundedIcon />,
            destination: '/mp-pos/details',
        }
    ]
}


export function HomeButtons ({ options }) {
    const navigate = useNavigate();

    return (
        <Box sx={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -30%)' }}>
            <Grid2 container spacing={2}>
                {options.map((option) => (
                    <Grid2 size={{ xs:12 }} key={option.name} marginBottom={5} >
                        <Button 
                            variant="contained"
                            color="info"
                            size="large"
                            fullWidth
                            startIcon={option.icon}
                            onClick={() => navigate(option.destination)}
                        >
                            {option.description}
                        </Button>
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    )
}


export function HomeNav ({ open, setOpen, options }) {
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOption = (destination) => {
        navigate(destination);
        handleClose();
    }

    return (
        <SpeedDial
            ariaLabel='Home SpeedDial'
            sx={{ position: 'absolute', top: '25%', left: '50%', transform: 'translate(-50%, -25%)' }}
            icon={ <SpeedDialIcon icon={<AttachMoneyOutlinedIcon />} openIcon={<PaidOutlinedIcon />} /> }
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction='down'
        >
            { options.map((action) => (
                <SpeedDialAction 
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.description}
                    onClick={() => handleOption(action.destination)}
                />
            ))}
        </SpeedDial>
    )
}

export default HomeNav;