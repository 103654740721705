import { Card, CardMedia, Divider, Grid2, Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import IsLoading from '../../utils/IsLoading';
import AlertMessage from '../../utils/AlertComponent';
import { isEmpty } from '../../utils/Utils';


function QrCode () {
    const { isLoading, qr_code, reference_number } = useSelector((state) => state.mpPos);
    const navigate = useNavigate();

    const handleVerify = () => {
        navigate(`/pos/verify/${reference_number}`);
    }

    return (
        <Paper sx={{ padding: 2}}>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                isEmpty(qr_code) ? (
                    <AlertMessage result='error' message='No QR code received' />
                ) : (
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12}}>
                            <Typography variant='h6' align='center' gutterBottom>Scan to pay</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    image={qr_code.qrcode_link}
                                    alt='Mobipaid QR Code'
                                />
                            </Card>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Divider />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button onClick={handleVerify} variant='outlined' endIcon={<DoneRoundedIcon />}>Verify</Button>
                            </Box>
                        </Grid2>
                    </Grid2>
                )
            )}
        </Paper>
    )
}

export default QrCode;