import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';


const initialState = {
    isLoading: false,
    room_id: '',
    reservation_details: {},
}


export const getReservationDetails = createAsyncThunk(
    'appPos/getReservationDetails',
    async (roomId, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.get(`/reservation_details?roomId=${roomId}`);
            dispatch(setRoomId(roomId));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    })


const appPosSlice = createSlice({
    name: 'appPos',
    initialState,
    reducers: {
        setRoomId (state, action) {
            state.room_id = action.payload;
        },
        unsetReservationDetails (state) {
            state.reservation_details = {};
            state.room_id = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReservationDetails.pending, (state) => {
            state.isLoading = true;
        }).addCase(getReservationDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.reservation_details = action.payload;
        }).addCase(getReservationDetails.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setRoomId, unsetReservationDetails } = appPosSlice.actions;
export default appPosSlice.reducer;