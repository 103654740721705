import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PaymentDetails from '../../utils/PaymentDetails';
import IsLoading from '../../utils/IsLoading';
import { setPymtDetails, getQrCode } from './MpPosSlice';
import {  isEmpty } from '../../utils/Utils';

function GetMpPosDetails () {
    const { isLoading, qr_code } = useSelector((state) => state.mpPos); 
    const [paymentDetails, setPaymentDetails] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isEmpty(paymentDetails)) {
            dispatch(setPymtDetails(paymentDetails));
            dispatch(getQrCode(paymentDetails));
            if (paymentDetails.scan_to_pay) {
                navigate('/mp-pos/qr-code');
            } else {
                if (!isEmpty(qr_code?.long_url)) {
                    window.location.href = qr_code.long_url;
                }
            }
        }
    }, [paymentDetails, qr_code, navigate, dispatch])

    return (
        <div>
            { isLoading ? <IsLoading isLoading={isLoading} /> : <PaymentDetails setPaymentDetails={setPaymentDetails} />}
        </div> 
    )
}

export default GetMpPosDetails;