import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { Button, Container, Grid2, Paper, TextField, Typography } from "@mui/material";
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import { login } from './AccountSlice';
import IsLoading from '../../utils/IsLoading';



function Login () {
    const { isLoading, isAuthenticated } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userData = {
            username: username,
            password: password,
        }
        dispatch(login(userData));
        navigate('/');
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate])

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : ( 
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h4' align="center" gutterBottom>Login</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Username' required onChange={(e) => setUsername(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Password' type='password' required onChange={(e) => setPassword(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<LoginRoundedIcon />}>Login</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                    <Typography variant='body2' align='center' marginTop={3}>
                        Forgot password? <Link to='/account/reset-password'>Reset password</Link>
                    </Typography>
                </Paper>
            )}
        </Container>
    )
}

export default Login;