import React from 'react';
import { Paper } from '@mui/material';

import AlertComponent from '../../utils/AlertComponent';

function Cancelled () {
    return (
        <Paper sx={{ padding: 2 }}>
            <AlertComponent result={'warning'} message={'Payment cancelled'} />
        </Paper>
    )
}

export default Cancelled;