import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';

import MenuAppBar from './MenuAppBar';


function Menu () {
    return (
        <Container maxWidth='sm'>
            <MenuAppBar />
            <Outlet />
        </Container>
    )
}

export default Menu;