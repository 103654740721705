import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Grid2, Paper, Typography } from '@mui/material';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { verifyPayment } from './PosSlice';
import IsLoading from '../../utils/IsLoading';
import { refreshToken, getUser } from '../account/AccountSlice';
import { isEmpty } from '../../utils/Utils';
import AlertComponent from '../../utils/AlertComponent';

function VerifyPayment () {
    const { isAuthenticated, user } = useSelector((state) => state.account);
    const { isLoading, verified, results } = useSelector((state) => state.pos);
    const { referenceNumber } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getResult = (result) => {
        if (result) {
            return 'success';
        } else {
            return 'warning';
        }
    }

    const Message = ({result}) => {
        if (result) {
            return <Typography variant='body2'>Payment successful!</Typography>;
        } else {
            return (
                <Box>
                    <Typography variant='body2'>Payment not completed.</Typography>
                    <Typography variant='body2'>{results.mp_response}</Typography>
                    <Typography variant='body2'>{results.app_response}</Typography>
                </Box>
            );
        }
    }


    useEffect(() => {
        if (referenceNumber && isAuthenticated) {
            dispatch(verifyPayment(referenceNumber));
        }
        if (!isAuthenticated && localStorage.getItem('refresh')) {
            dispatch(refreshToken());
        }
        if (isAuthenticated && isEmpty(user)) {
            dispatch(getUser());
        }
        if (isEmpty(localStorage.getItem('refresh'))) {
            navigate('/account/login');
        }
    }, [dispatch, navigate, isAuthenticated, user, referenceNumber]);

    return (
        <Paper sx={{ padding: 2 }}>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Grid2 container spacing={2} alignItems='center' justifyContent='center'>
                    <Grid2 size={{ xs: 12 }}>
                        <AlertComponent result={getResult(verified)} message={<Message result={verified} />} showTitle={true} />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        { verified ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button onClick={() => navigate('/')} variant='outlined' color='success' endIcon={<HomeRoundedIcon />} >Home</Button>
                            </Box> ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <Button onClick={() => dispatch(verifyPayment(referenceNumber))} variant='outlined' color='warning' endIcon={<ReplayRoundedIcon />}>Re-try</Button>
                                <Button onClick={() => navigate('/')} variant='outlined' color='warning' endIcon={<HomeRoundedIcon />} >Home</Button>
                            </Box>
                        )}                
                    </Grid2>
                </Grid2>
            )}        
        </Paper>
    )
}

export default VerifyPayment;