import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Container, Grid2, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';

import IsLoading from '../../utils/IsLoading';
import { updateDefaultCurrency } from './AccountSlice';


function UpdateDefaultCurrency () {
    const { isLoading, isAuthenticated, user } = useSelector((state) => state.account);
    const [defaultCurrency, setDefaultCurrency] = useState(user.default_currency || '');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            default_currency: defaultCurrency
        }
        dispatch(updateDefaultCurrency(data));
        navigate('/');
    }

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h5' align="center" gutterBottom>Default currency</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <FormControl variant='standard' sx={{ width: '150px' }}>
                                    <InputLabel id='currencyLabel'>Default currency</InputLabel>
                                    <Select
                                        labelId="currencyLabel"
                                        id="currencyInput"
                                        value={defaultCurrency}
                                        label="Currency"
                                        onChange={(e) => setDefaultCurrency(e.target.value)}
                                    >
                                        { user?.mp_currencies.map((currency) => (
                                            <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<LockResetRoundedIcon />}>Update</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </Paper>
            )}
        </Container>
    )
}

export default UpdateDefaultCurrency;