import axios from 'axios';
import { toast } from 'react-toastify';
import { createTheme }from '@mui/material/styles';


export const theme = createTheme({
    palette: {
       primary: {
        main: '#E55E40',
       },
       secondary: {
        main: '#2E02A8',
       },
       info: {
        main: '#171A37'
       }
    }
})


export const setAxiosAuthToken = (token) => {
    if (typeof token !== 'undefined' && token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export const toastOnError = error => {
    if (error.response) {
        toast.error(JSON.stringify(error.response.data), {toastId: 'error1'});
    } else if (error.message) {
        toast.error(JSON.stringify(error.message), {toastId: 'error2'});
    } else {
        toast.error(JSON.stringify(error), {toastId: 'error3'});
    }
}


export const isEmpty = value => 
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'object' && Object.values(value).every(value => value === '' || value === null)) ||
    (typeof value === 'string' && value.trim().length === 0)