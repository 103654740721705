import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';


const initialState = {
    isLoading: false,
    payment_details: {},
    qr_code: {},
    reference_number: '',
    emv_qr_code: ''
}


export const getQrCode = createAsyncThunk(
    'mpPos/getQrCode',
    async (paymentData, { _, rejectWithValue }) => {
        try {
            const response = await axios.post('/qrcode/', paymentData);
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getEmvQrCode = createAsyncThunk(
    'mpPos/getEmvQrCode',
    async (paymentData, { _, rejectWithValue }) => {
        try {
            const response = await axios.post('/emv/qrcode/', paymentData);
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const mpPosSlice = createSlice({
    name: 'mpPos',
    initialState,
    reducers: {
        setPymtDetails (state, action) {
            state.payment_details = action.payload;
        },
        unsetPymtDetails (state) {
            state.payment_details = {};
            state.qr_code = '';
            state.reference_number = '';
            state.emv_qr_code = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getQrCode.pending, (state) => {
            state.isLoading = true;
        }).addCase(getQrCode.fulfilled, (state, action) => {
            state.isLoading = false;
            state.qr_code = action.payload.qr_code;
            state.reference_number = action.payload.reference_number;
        }).addCase(getQrCode.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getEmvQrCode.pending, (state) => {
            state.isLoading = true;
        }).addCase(getEmvQrCode.fulfilled, (state, action) => {
            state.isLoading = false;
            state.emv_qr_code = action.payload.qrcode_link;
        }).addCase(getEmvQrCode.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setPymtDetails, unsetPymtDetails } = mpPosSlice.actions;
export default mpPosSlice.reducer;