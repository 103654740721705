import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Avatar, Badge, Box, Container, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';

import AccountMenu from './AccountMenu';

function MenuAppBar () {
    const { isAuthenticated, user } = useSelector((state) => state.account);
    const avatarButton = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = () => {
        setAnchorEl(avatarButton.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const loggedUser = isAuthenticated && user?.username ? user.username : 'Anonymous';

    return (
        <AppBar position='static' style={{ backgroundColor: 'white', marginBottom: 20, marginTop: 10 }}>
            <Container maxWidth='xl' >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Box marginRight={1}>
                                <img src={'https://storage.googleapis.com/mp-ohip-bucket-prod/logos/MP_sm_black.png'} alt='Mobipaid logo' height={30} />
                            </Box>
                            <Badge badgeContent={'Beta'} color='inherit'>
                                <Typography variant='h4' align='center' alignSelf={'center'} color='black'>POS</Typography>
                            </Badge>
                        </Box>
                    </Link>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Tooltip title='Account'>
                            <IconButton ref={avatarButton} onClick={handleClick} size='small' aria-controls={anchorEl ? 'account-menu' :  undefined} aria-expanded={anchorEl ?  'true' : undefined} aria-haspopup='true'>
                                { loggedUser === 'Anonymous' ?  <Avatar>A</Avatar> : (
                                    <Avatar src={user.logo_url} />
                                )}
                            </IconButton>
                        </Tooltip>
                        <AccountMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} loggedUser={loggedUser}  />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MenuAppBar;