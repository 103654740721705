import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Container, Grid2, Paper, TextField, Typography } from '@mui/material';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';

import IsLoading from '../../utils/IsLoading';
import { updateUser } from './AccountSlice';

function UpdateUser () {
    const { isLoading, isAuthenticated, user } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState(user.username);
    const [email, setEmail] = useState(user.email);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userData = {
            username: username,
            email: email,
            first_name: firstName,
            last_name: lastName,
        }
        dispatch(updateUser(userData));
    }

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate])

    return (
        <Container maxWidth='sm'>
            { isLoading ? <IsLoading isLoading={isLoading} /> : (
                <Paper sx={{ padding: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2} align='center'>
                            <Grid2 size={{ xs: 12}}>
                                <Typography variant='h5' align="center" gutterBottom>User details</Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Username' value={username} disabled onChange={(e) => setUsername(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Email' value={email} type='email' required onChange={(e) => setEmail(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='First name' value={firstName} required onChange={(e) => setFirstName(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField label='Last name' value={lastName} required onChange={(e) => setLastName(e.target.value)} />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Button type='submit' disabled={isLoading} variant='contained' endIcon={<LockResetRoundedIcon />}>Update</Button>
                            </Grid2>
                        </Grid2>
                    </form>
                </Paper>
            )}
        </Container>
    )
}

export default UpdateUser;