import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Grid2, IconButton, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import FindReplaceRoundedIcon from '@mui/icons-material/FindReplaceRounded';

import { isEmpty } from '../../utils/Utils';
import AlertComponent from '../../utils/AlertComponent';
import { getReservationDetails } from './AppPosSlice';

function ReservationDetails () {
    const { isLoading, reservation_details } = useSelector((state) => state.appPos);
    const [roomId, setRoomId] = useState('');
    const dispatch = useDispatch();

    const GuestDetails = () => {
        return (
            <Typography variant='h6'>Guest details</Typography>
        )
    }

    const ReservationDetails = () => {
        return (
            <Box>
                <Typography variant='body2'>Room {reservation_details.room_id}: {reservation_details.first_name} {reservation_details.last_name}</Typography>
                <Typography variant='body2'>Folio balance: {reservation_details.folio_balance.currencyCode}{reservation_details.folio_balance.amount}</Typography>
            </Box>
        )
    }

    const handleSubmit = () => {
        if (roomId) {
            dispatch(getReservationDetails(roomId));
        } else {
            toast.error('Provide room ID');
        }
    }

    return (
        <Paper sx={{ padding: 2 }}>
            { isLoading ? (
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12 }}>
                        <LinearProgress color='secondary' />
                    </Grid2>
                </Grid2>
            ) : (
                isEmpty(reservation_details) ? (
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 12 }}>
                                <GuestDetails />
                            </Grid2>
                            <Grid2 size={{ xs: 8 }}>
                                <TextField variant='standard' required label='Room Id' value={roomId} onChange={(e) => setRoomId(e.target.value)} />                             
                            </Grid2>
                            <Grid2 size={{ xs: 4 }}>
                                <IconButton type='submit' color='secondary' size='large'>
                                    <FindReplaceRoundedIcon />
                                </IconButton>
                            </Grid2>
                        </Grid2>
                    </form>
                ) : (
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12 }}>
                            <GuestDetails />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <AlertComponent
                                result='success'
                                message={<ReservationDetails />}
                                showTitle={false}
                            />
                        </Grid2>
                    </Grid2>
                )
            )}
        </Paper>
    )
}

export default ReservationDetails;